<template>
  <div class="flex justify-center items-center">
    <div class="mask mask-hexagon w-6 h-6 text-sm flex justify-center items-center bg-[#7E5840] bg-opacity-20 text-[#7E5840] font-bold">
      <span>3</span>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style>

</style>