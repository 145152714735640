<template>
  <div class="flex justify-center items-center">
    <div class="mask mask-hexagon w-6 h-6 text-sm flex justify-center items-center bg-yellow-500/20 text-yellow-500 font-bold">
      <span>1</span>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style>

</style>